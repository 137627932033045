// eslint-disable-next-line import/prefer-default-export
export default function inventoryMenuItems(active, params = {}) {
  const menuItems = [
    {
      name: 'settings-asset-general',
      layout: 'General',
    },
    {
      name: 'settings-asset-condition',
      layout: 'Asset Condition',
    },
    {
      name: 'settings-asset-tags',
      layout: 'Asset Tags',
    },
  ]
  menuItems.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item.active = item.name === active
    // eslint-disable-next-line no-param-reassign
    item.route = { name: item.name, params }
  })

  return {
    menuItems,
  }
}
