<template>
  <sub-menu-component
    :menu-items="menuItems"
  >
    <router-view @updateMenu="updateMenu" />
  </sub-menu-component>
</template>

<script>
import { ref } from '@vue/composition-api'
import inventoryMenuItems from '@/views/components/sub-menu/settings-sub-menu/inventory'

export default {
  name: 'Inventory',
  setup() {
    const menuItems = ref()
    return {
      menuItems,
    }
  },
  methods: {
    updateMenu(active) {
      this.menuItems = inventoryMenuItems(active).menuItems
    },
  },
}
</script>
